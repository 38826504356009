export const useQueryParamToComputed = () => {
  const router = useRouter();
  const route = useRoute();

  const queryParamToComputed = <T = string>(
    k: string,
    {
      get = (v) => v || "",
      set = (v) => v,
    }: {
      get?: (v: any) => any;
      set?: (v: T) => any;
    } = {},
  ) =>
    computed({
      get: () => get(route.query[k]) as T,
      set: (v) => {
        console.log("set", k, JSON.stringify(v));
        router.replace({
          ...route,
          query: {
            ...route.query,
            [k]: v ? set(v) : undefined,
          },
        });
      },
    });

  return {
    queryParamToComputed,
  };
};
